<div class="bg-white">
    <!-- Navbar -->
    <!-- navbar login -->


    <!-- Page Content -->
    <div class="py-md-5 py-12 bg-dark ">
        <div class="container">
            <!-- Hero Section -->
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12">
                    <div class="mb-4 mb-xl-0 text-center text-md-start">
                        <!-- Caption -->
                        <h1 class="display-2 fw-bold mb-3 text-white ls-sm ">Become a Full Stack Developer </h1>
                        <p class="mb-4 lead text-white-50">
                            In this bootcamp program, we are going to learn how to build a product using emerging technologies.
                        </p>
                        <!-- List -->
                        <div class="mb-6 mb-0">
                            <ul class="list-unstyled fs-4 ">
                                <li class="mb-2 text-white-50"><span class="me-2 "><i class="fe fe-clock text-warning "></i></span><span class="align-top">12 Weeks</span></li>
                                <!-- <li class="mb-2 text-white-50"><span class="me-2 "><i class="fe fe-video text-warning "></i></span><span class="align-top">12 Videos</span></li> -->
                                <!-- <li class="mb-2 text-white-50"><span class="me-2 "><i class="fe fe-users text-warning "></i></span><span class="align-top">10,234+ Enrolled</span></li> -->
                            </ul>
                        </div>
                        <!-- <a href="https://www.youtube.com/watch?v=JRzWRZahOVU" class="popup-youtube btn btn-white btn-lg fs-4">Watch Preview</a> -->
                    </div>
                </div>
                <div class="offset-xl-1 col-xl-5 col-lg-6 col-md-12" *ngIf="showRegistrationForm">
                    <!-- Card -->
                    <div class="card" style="z-index: 1;">
                        <!-- Card body -->
                        <div class="card-body p-6">
                            <div class="mb-4">
                                <h1 class="mb-4 lh-1 fw-bold h2">Create Free Account</h1>

                            </div>

                            <!-- Form -->
                            <form>
                                <!-- Username -->
                                <div class="mb-3">
                                    <label for="name" class="form-label sr-only">Name</label>
                                    <input type="text" id="name" class="form-control" name="name" placeholder="Name" required="" autocomplete="off">
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label sr-only">Email</label>
                                    <input type="email" id="email" class="form-control" name="email" placeholder="Email" required="" autocomplete="off">
                                </div>
                                <!-- Password -->
                                <div class="mb-3">
                                    <label for="password" class="form-label sr-only">Password</label>
                                    <input type="password" id="password" class="form-control" name="password" placeholder="Password" required="" autocomplete="off">
                                </div>
                                <!-- Button -->
                                <div class="d-grid">
                                    <button type="submit" class="btn btn-primary">Start Courses for Free</button>
                                </div>
                            </form>
                        </div>
                        <!-- Card Footer -->
                        <div class="card-footer bg-white px-6 py-4">
                            <p class="mb-0">By continuing you accept the <a href="#" class="text-inherit fw-semi-bold">Terms of Use</a>,<a href="#" class="text-inherit fw-semi-bold"> Privacy
                    Policy</a>, and <a href="#" class="text-inherit fw-semi-bold">Data Policy</a>
                            </p>
                        </div>
                    </div>
                    <!-- Pattern -->
                    <div class="position-relative">
                        <div class="position-absolute bottom-0 end-0 me-md-n3 mb-md-n6 me-lg-n4 mb-lg-n4 me-xl-n6 mb-xl-n8 d-none d-md-block ">
                            <img src="../../assets/images/pattern/dots-pattern.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Feature section -->
    <div class="py-4 shadow-sm position-relative bg-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                    <!-- Feature -->
                    <div class="text-dark fw-semi-bold lh-1 fs-4 mb-3 mb-lg-0"><span class="icon-shape icon-xs rounded-circle bg-light-warning text-center me-2"><i
                  class="mdi mdi-check text-dark-warning "></i></span><span class="align-middle">Shareable
                Certificate</span></div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <!-- Feature -->
                    <div class="text-dark fw-semi-bold lh-1 fs-4 mb-3 mb-lg-0"><span class="icon-shape icon-xs rounded-circle bg-light-warning text-center me-2"><i
                  class="mdi mdi-check text-dark-warning "></i></span><span class="align-middle">Flexible
                Deadlines</span></div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <!-- Feature -->
                    <div class="text-dark fw-semi-bold lh-1 fs-4 mb-3 mb-md-0"><span class="icon-shape icon-xs rounded-circle bg-light-warning text-center me-2"><i
                  class="mdi mdi-check text-dark-warning "></i></span><span class="align-middle">100%
                Online Courses</span></div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <!-- Feature -->
                    <div class="text-dark fw-semi-bold lh-1 fs-4"><span class="icon-shape icon-xs rounded-circle bg-light-warning text-center me-2"><i
                  class="mdi mdi-check text-dark-warning "></i></span><span class="align-middle">Approx.24
                hours</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Section -->
    <div class="py-8 py-lg-18 bg-light">
        <div class="container">
            <div class="row mb-8 justify-content-center">
                <div class="col-lg-8 col-md-12 col-12 text-center">
                    <!-- caption -->
                    <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">Course Description</span>
                    <h2 class="mb-2 display-4 fw-bold">What will you learn?</h2>
                    <p class="lead">You will learn how to build a product using various technologies like HTML5,JavaScript, Java, NodeJS etc..</p>
                </div>
            </div>
            <!-- row -->
            <div class="row">
                <div class="col-lg-6 col-md-12 col-12">
                    <!-- Features -->
                    <div class="card mb-4">
                        <!-- Card body -->
                        <div class="card-body p-6">
                            <div class="d-md-flex mb-4">
                                <div class="mb-3 mb-md-0">
                                    <!-- Img -->
                                    <img src="../../assets/images/svg/feature-icon-1.svg" alt="" class=" bg-primary icon-shape icon-xxl rounded-circle">
                                </div>
                                <!-- Content -->
                                <div class="ms-md-4">
                                    <h2 class="fw-bold mb-1">Introduction to JavaScript <span class="badge bg-warning ms-2">Free</span></h2>
                                    <p class="text-uppercase fs-6 fw-semi-bold mb-0"><span class="text-dark">Courses -
                        1</span> <span class="ms-3">6 Lessons</span> <span class="ms-3">1 Hour 12 Min</span></p>
                                </div>
                            </div>
                            <!-- <p class="mb-4 fs-4">In et tempus dui, in porta dolor. Donec molestie a purus ut interdum. Donec quis felis dignissim, luctus libero ornare.</p>
                            <a href="#" class="btn-link" data-bs-toggle="modal" data-bs-target="#courseModal">View Chapter Details <i
                    class="fe fe-plus"></i></a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                    <!-- features -->
                    <div class="card mb-4">
                        <div class="card-body p-6">
                            <div class="d-md-flex mb-4">
                                <div class="mb-3 mb-md-0">
                                    <img src="../../assets/images/svg/feature-icon-2.svg" alt="" class=" bg-primary icon-shape icon-xxl rounded-circle">
                                </div>
                                <div class="ms-md-4">
                                    <h2 class="fw-bold mb-1">JavaScript Beginning</h2>
                                    <p class="text-uppercase fs-6 fw-semi-bold mb-0"><span class="text-dark">Courses -
                        2</span> <span class="ms-3">4 Lessons</span> <span class="ms-3">32 Min</span></p>
                                </div>
                            </div>
                            <!-- <p class="mb-4 fs-4">Malesuada fames ac turpis egesta mpor tempus tincidunt. Aliquam congue lacus ac tellus consectetur malesuada.</p>
                            <a href="#" class="btn-link" data-bs-toggle="modal" data-bs-target="#courseModal">View Chapter Details <i
                    class="fe fe-plus"></i></a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                    <!-- features -->
                    <div class="card mb-4">
                        <div class="card-body p-6">
                            <div class="d-md-flex mb-4">
                                <div class="mb-3 mb-md-0">
                                    <img src="../../assets/images/svg/feature-icon-3.svg" alt="" class=" bg-primary icon-shape icon-xxl rounded-circle">
                                </div>
                                <div class="ms-md-4">
                                    <h2 class="fw-bold mb-1">Variables and Constants</h2>
                                    <p class="text-uppercase fs-6 fw-semi-bold mb-0"><span class="text-dark">Courses -
                        3</span> <span class="ms-3">8 Lessons</span> <span class="ms-3">10 Min</span></p>
                                </div>
                            </div>
                            <!-- <p class="mb-4 fs-4">Aliquam pulvinar eros a dictur vitae diam imperdiet, ornare turpis vequet elit nec, imperdiet lectuna liquam qs.</p>
                            <a href="#" class="btn-link" data-bs-toggle="modal" data-bs-target="#courseModal">View Chapter Details <i
                    class="fe fe-plus"></i></a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                    <!-- features -->
                    <div class="card mb-4">
                        <div class="card-body p-6">
                            <div class="d-md-flex mb-4">
                                <div class="mb-3 mb-md-0">
                                    <img src="../../assets/images/svg/feature-icon-4.svg" alt="" class=" bg-primary icon-shape icon-xxl rounded-circle">
                                </div>
                                <div class="ms-md-4">
                                    <h2 class="fw-bold mb-1">Types and Operators</h2>
                                    <p class="text-uppercase fs-6 fw-semi-bold mb-0"><span class="text-dark">Courses -
                        4</span> <span class="ms-3">10 Lessons</span> <span class="ms-3">32 Min</span></p>
                                </div>
                            </div>
                            <!-- <p class="mb-4 fs-4">In lobortis quam eu augue spendisse imperdiet nec orci ipsum, tempus pharetra posuere imperdiet, lacinia a nisl.</p>
                            <a href="#" class="btn-link" data-bs-toggle="modal" data-bs-target="#courseModal">View Chapter Details <i
                    class="fe fe-plus"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Section -->
    <div class="py-8 py-lg-18">
        <div class="container">
            <div class="row mb-6 align-items-center justify-content-center">
                <div class="col-md-10">
                    <div class="row align-items-center ">
                        <div class="col-xl-6 col-lg-7 col-md-12 col-12 order-1 text-center text-lg-start ">
                            <!-- caption -->
                            <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">YOUR INSTRUCTOR</span>
                            <h2 class="mb-2 display-4 fw-bold mb-3">Hi, I’m <span class="text-primary">Naresh Kumar</span>,
                                <br>I will be taking you through lessons.</h2>
                            <p class="fs-3 pe-6">Build a product website. Get started building a site today.</p>

                            <hr class="my-5">
                            <!-- Counter -->
                            <!-- <div class="row">
                                <div class="col-sm mb-3 mb-lg-0">
                                    <h2 class="h1 fw-bold mb-0 ls-xs">45</h2>
                                    <p class="mb-0">Lessons</p>
                                </div>
                                <div class="col-lg-5 col-sm mb-3 mb-lg-0">
                                    <h2 class="h1 fw-bold mb-0 ls-xs">10,500+</h2>
                                    <p class="mb-0">Students</p>
                                </div>
                                <div class="col-sm mb-3 mb-lg-0">
                                    <h2 class="h1 fw-bold mb-0 ls-xs">12+</h2>
                                    <p class="mb-0">Learning Hours</p>
                                </div>
                            </div> -->
                        </div>
                        <!-- Img -->
                        <div class="offset-xl-1 col-xl-5 col-lg-5 col-12 mb-6 mb-lg-0 order-lg-2 text-center ">
                            <img src="../../assets/images/instructor/naresh.png" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Section -->
    <!-- <div class="pb-8 pb-lg-18">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-12">

    <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl text-center">Trusted by
              top-tier product companies</span>
    <div class="row mt-8">

        <div class="col-xl-2 col-lg-4 col-md-4 col-6 text-center mb-4 mb-xl-0">
            <img src="../../assets/images/brand/gray-logo-stripe.svg" alt="">
        </div>

        <div class="col-xl-2 col-lg-4 col-md-4 col-6 text-center mb-4 mb-xl-0">
            <img src="../../assets/images/brand/gray-logo-airbnb.svg" alt="">
        </div>

        <div class="col-xl-2 col-lg-4 col-md-4 col-6 text-center mb-4 mb-xl-0">
            <img src="../../assets/images/brand/gray-logo-discord.svg" alt="">
        </div>

        <div class="col-xl-2 col-lg-4 col-md-4 col-6 text-center mb-4 mb-xl-0">
            <img src="../../assets/images/brand/gray-logo-intercom.svg" alt="">
        </div>

        <div class="col-xl-2 col-lg-4 col-md-4 col-6 text-center mb-4 mb-xl-0">
            <img src="../../assets/images/brand/gray-logo-pinterest.svg" alt="">
        </div>

        <div class="col-xl-2 col-lg-4 col-md-4 col-6 text-center mb-4 mb-xl-0">
            <img src="../../assets/images/brand/gray-logo-netflix.svg" alt="">
        </div>
    </div>
</div>
</div>
</div>
</div> -->
    <!-- Section -->
    <!-- <div class="py-8 py-lg-18 bg-light">
        <div class="container">
            <div class="row mb-8 justify-content-center">
                <div class="col-lg-6 col-md-12 col-12 text-center">

                    <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">Testimonials</span>
                    <h2 class="mb-2 display-4 fw-bold ">What our learners are saying </h2>
                    <p class="lead">12+ million people are already learning on Geeks</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12 mb-4 mb-lg-0">

                    <div class="card shadow-lg">

                        <div class="card-body p-4 p-md-8 text-center">
                            <i class="mdi mdi-48px mdi-format-quote-open text-light-primary lh-1"></i>
                            <p class="lead text-dark mt-3">The generated lorem Ipsum is therefore always free from repetition, injected humour, or words etc generate lorem Ipsum which looks racteristic reasonable.</p>
                        </div>

                        <div class="card-footer bg-primary text-center border-top-0">
                            <div class="mt-n8"><img src="../../assets/images/avatar/avatar-1.jpg" alt="" class="rounded-circle border-primary avatar-xl border border-4"></div>
                            <div class="mt-2 text-white">
                                <h3 class="text-white mb-0">Gladys Colbert</h3>
                                <p class="text-white-50 mb-1">Software Engineer at Palansite</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">

                    <div class="card shadow-lg">
                        <div class="card-body p-4 p-md-8 text-center">
                            <i class="mdi mdi-48px mdi-format-quote-open text-light-info lh-1"></i>
                            <p class="lead text-dark mt-3">Lorem ipsum dolor sit amet, consectetur adipi scing elit. Sed vel felis imperdiet, lacinia metus malesuada diamamus rutrum turpis leo, id tincidunt magna sodales.</p>
                        </div>

                        <div class="card-footer bg-info text-center border-top-0">
                            <div class="mt-n8"><img src="../../assets/images/avatar/avatar-2.jpg" alt="" class="rounded-circle border-info avatar-xl border border-4"></div>
                            <div class="mt-2 text-white">
                                <h3 class="text-white mb-0">Ella Jones</h3>
                                <p class="text-white-50 mb-1">Software Engineer at Classroom</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="py-8 py-lg-18">
        <div class="container">
            <div class="row mb-8 justify-content-center">
                <div class="col-lg-6 col-md-12 col-12 text-center">

                    <span class="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">Need to Know</span>
                    <h2 class="mb-2 display-4 fw-bold ">Frequently Asked Questions.</h2>
                    <p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis non accumsan in, tempor dictum neque.</p>
                </div>
            </div>

            <div class="row justify-content-center ">
                <div class="col-lg-6 col-md-8 col-12">
                    <div class="accordion accordion-flush" id="accordionExample">
                        <div class="border-bottom py-3" id="headingOne">
                            <h3 class="mb-0 fw-bold">
                                <a href="#" class="d-flex align-items-center text-inherit text-decoration-none active" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span class="me-auto">
                      What is the cost of an online course
                    </span>
                                    <span class="collapse-toggle ms-4">
                      <i class="fe fe-plus text-primary"></i>
                    </span>
                                </a>
                            </h3>
                        </div>
                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="py-3 fs-4">
                                Create beautiful website with this Geeks UI template. Get started building a site today.
                            </div>
                        </div>
                        <div class="border-bottom py-3" id="headingTwo">
                            <h3 class="mb-0 fw-bold">
                                <a href="#" class="d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span class="me-auto">
                      What do I need to take a course?
                    </span>
                                    <span class="collapse-toggle ms-4">
                      <i class="fe fe-plus text-primary"></i>
                    </span>
                                </a>
                            </h3>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="py-3 fs-4">
                                Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                        <div class="border-bottom py-3 " id="headingThree">
                            <h3 class="mb-0 fw-bold">
                                <a href="#" class="d-flex align-items-center text-inherit text-decoration-none active" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span class="me-auto">
                      What do I receive for taking this course?
                    </span>
                                    <span class="collapse-toggle ms-4">
                      <i class="fe fe-plus text-primary"></i>
                    </span>
                                </a>
                            </h3>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="py-3 fs-4">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                            </div>
                        </div>

                        <div class="pt-3 " id="headingFour">
                            <h3 class="mb-0 fw-bold">
                                <a href="#" class="d-flex align-items-center text-inherit text-decoration-none active" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <span class="me-auto">
                      What willI get if I subscribe to this Certificate?
                    </span>
                                    <span class="collapse-toggle ms-4">
                      <i class="fe fe-plus text-primary"></i>
                    </span>
                                </a>
                            </h3>
                        </div>
                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="py-3 fs-4">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                            </div>
                        </div>
                    </div>
                    <div class="mt-10 text-center">
                        <a href="#" class="btn btn-outline-white">More questions? Visit the <span class="text-primary">Learner Help
                  Center.</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- call to action -->
    <div class="py-lg-16 py-10 bg-dark" style="background: url(../../assets/images/background/course-graphics.svg)no-repeat; background-size: cover; background-position: top center">
        <div class="container">
            <!-- row -->
            <div class="row justify-content-center text-center">
                <div class="col-md-9 col-12">
                    <!-- heading -->
                    <h2 class="display-4 text-white">Join more than 1 million learners worldwide</h2>
                    <p class="lead text-white px-lg-12 mb-6">Effective learning starts with curiosity. Improve your skill every day. Learn2Build team mentors makes it easier.</p>
                    <!-- button -->
                    <!-- <div class="d-grid d-md-block">
                        <a routerLink="auth/register" class="btn btn-primary mb-2 mb-md-0">Start Learning for Free</a> &nbsp;
                        <a routerLink="auth/register" class="btn btn-info">Corporate Training</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <!-- modal -->
    <!-- Payment Modal -->
    <div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header align-items-center d-flex">
                    <h4 class="modal-title" id="paymentModalLabel">
                        Add New Payment Method
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

            </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div>
                        <!-- Form -->
                        <form class="row mb-4">
                            <div class="mb-3 col-12 col-md-12 mb-4">
                                <h5 class="mb-3">Credit / Debit card</h5>
                                <!-- Radio button -->
                                <div class="d-inline-flex">
                                    <div class="form-check me-2">
                                        <input type="radio" id="paymentRadioOne" name="paymentRadioOne" class="form-check-input" />
                                        <label class="form-check-label" for="paymentRadioOne"><img
                        src="../../assets/images/creditcard/americanexpress.svg" alt="" /></label>
                                    </div>
                                    <!-- Radio button -->
                                    <div class="form-check me-2">
                                        <input type="radio" id="paymentRadioTwo" name="paymentRadioOne" class="form-check-input" />
                                        <label class="form-check-label" for="paymentRadioTwo"><img
                        src="../../assets/images/creditcard/mastercard.svg" alt="" /></label>
                                    </div>

                                    <!-- Radio button -->
                                    <div class="form-check">
                                        <input type="radio" id="paymentRadioFour" name="paymentRadioOne" class="form-check-input" />
                                        <label class="form-check-label" for="paymentRadioFour"><img src="../../assets/images/creditcard/visa.svg"
                        alt="" /></label>
                                    </div>
                                </div>
                            </div>
                            <!-- Name on card -->
                            <div class="mb-3 col-12 col-md-4">
                                <label for="nameoncard" class="form-label">Name on card</label>
                                <input id="nameoncard" type="text" class="form-control" name="nameoncard" placeholder="Name" required />
                            </div>
                            <!-- Month -->
                            <div class="mb-3 col-12 col-md-4">
                                <label class="form-label">Month</label>
                                <select class="selectpicker" data-width="100%">
                    <option value="">Month</option>
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                  </select>
                            </div>
                            <!-- Year -->
                            <div class="mb-3 col-12 col-md-4">
                                <label class="form-label">Year</label>
                                <select class="selectpicker" data-width="100%">
                    <option value="">Year</option>
                    <option value="June">2018</option>
                    <option value="July">2019</option>
                    <option value="August">2020</option>
                    <option value="Sep">2021</option>
                    <option value="Oct">2022</option>
                  </select>
                            </div>
                            <!-- Card number -->
                            <div class="mb-3 col-md-8 col-12">
                                <label for="cc-mask" class="form-label">Card Number</label>
                                <input type="text" class="form-control" id="cc-mask" data-inputmask="'mask': '9999 9999 9999 9999'" inputmode="numeric" placeholder="xxxx-xxxx-xxxx-xxxx" required />
                            </div>
                            <!-- CVV -->
                            <div class="mb-3 col-md-4 col-12">
                                <div class="mb-3">
                                    <label for="cvv" class="form-label">CVV Code
                      <i class="fas fa-question-circle ms-1" data-bs-toggle="tooltip" data-placement="top" title=""
                        data-original-title="A 3 - digit number, typically printed on the back of a card."></i></label>
                                    <input type="password" class="form-control" name="cvv" id="cvv" placeholder="xxx" maxlength="3" inputmode="numeric" required />
                                </div>
                            </div>
                            <!-- Button -->
                            <div class="col-md-6 col-12">
                                <button class="btn btn-primary" type="submit">
                    Add New Card
                  </button>
                                <button class="btn btn-outline-white" type="button" data-bs-dismiss="modal">
                    Close
                  </button>
                            </div>
                        </form>
                        <span><strong>Note:</strong> that you can later remove your card at
                the account setting page.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addSectionModal" tabindex="-1" role="dialog" aria-labelledby="addSectionModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="addSectionModalLabel">
                        Add New Section
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

          </button>
                </div>
                <div class="modal-body">
                    <input class="form-control mb-3" type="text" placeholder="Add new section" />
                    <button class="btn btn-primary" type="Button">
              Add New Section
            </button>
                    <button class="btn btn-outline-white" data-bs-dismiss="modal" aria-label="Close">
              Close
            </button>
                </div>

            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addLectureModal" tabindex="-1" role="dialog" aria-labelledby="addLectureModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="addLectureModalLabel">
                        Add New Lecture
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

          </button>
                </div>
                <div class="modal-body">
                    <input class="form-control mb-3" type="text" placeholder="Add new lecture" />
                    <button class="btn btn-primary" type="Button">
              Add New Lecture
            </button>
                    <button class="btn btn-outline-white" data-bs-dismiss="modal" aria-label="Close">
              Close
            </button>
                </div>
            </div>
        </div>
    </div>



    <!-- Modal -->
    <div class="modal fade" id="newCatgory" tabindex="-1" role="dialog" aria-labelledby="newCatgoryLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title mb-0" id="newCatgoryLabel">
                        Create New Category
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

          </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3 mb-2">
                            <label class="form-label" for="title">Title<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Write a Category" id="title" required>
                            <small>Field must contain a unique value</small>
                        </div>
                        <div class="mb-3 mb-2">
                            <label class="form-label">Slug</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="slug">https://example.com</span>
                                </div>
                                <input type="text" class="form-control" id="basic-url" aria-describedby="slug" placeholder="designcourses" required>
                            </div>
                            <small>Field must contain a unique value</small>
                        </div>
                        <div class="mb-3 mb-2">
                            <label class="form-label">Parent</label>
                            <select class="selectpicker" data-width="100%">
                  <option value="">Select</option>
                  <option value="Course">Course</option>
                  <option value="Tutorial">Tutorial</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Company">Company</option>
                </select>
                        </div>
                        <div class="mb-3 mb-3">
                            <label class="form-label">Description</label>
                            <div id="editor">
                                <br>
                                <h4>One Ring to Rule Them All</h4>
                                <br>
                                <p>
                                    Three Rings for the
                                    <i> Elven-kingsunder</i> the sky,
                                    <br> Seven for the
                                    <u>Dwarf-lords</u> in halls of stone, Nine for Mortal Men,
                                    <br> doomed to die, One for the Dark Lord on his dark throne.
                                    <br> In the Land of Mordor where the Shadows lie.
                                    <br>
                                    <br>
                                </p>
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="form-label">Enabled</label>
                            <div class="form-check form-switch">
                                <input type="checkbox" class="form-check-input" id="customSwitch1" checked>
                                <label class="form-check-label" for="customSwitch1"></label>
                            </div>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-primary">Add New Category</button>
                            <button type="button" class="btn btn-outline-white" data-bs-dismiss="modal">
                  Close
                </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>



    <!-- Modal -->


    <!-- Course Modal -->
    <div class="modal fade" id="courseModal" tabindex="-1" aria-labelledby="courseModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header py-4 align-items-lg-center">
                    <div class="d-lg-flex">
                        <div class="mb-3 mb-lg-0">
                            <img src="../../assets/images/svg/feature-icon-1.svg" alt="" class=" bg-primary icon-shape icon-xxl rounded-circle">
                        </div>
                        <div class="ms-lg-4">
                            <h2 class="fw-bold mb-md-1 mb-3">Introduction to JavaScript <span class="badge bg-warning ms-2">Free</span></h2>
                            <p class="text-uppercase fs-6 fw-semi-bold mb-0"><span class="text-dark">Courses -
                    1</span> <span class="ms-3">6 Lessons</span> <span class="ms-3">1 Hour 12 Min</span></p>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

            </button>
                </div>
                <div class="modal-body">
                    <h3>In this course you’ll learn:</h3>
                    <p class="fs-4">Vanilla JS is a fast, lightweight, cross-platform framework for building incredible, powerful JavaScript applications.</p>
                    <ul class="list-group list-group-flush">
                        <!-- List group item -->
                        <li class="list-group-item ps-0">
                            <a href="#" class="d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
                        class="mdi mdi-play fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 7s</span>
                                </div>
                            </a>
                        </li>
                        <!-- List group item -->
                        <li class="list-group-item ps-0">
                            <a href="#" class="d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
                        class="mdi mdi-play fs-4"></i></span>
                                    <span>Installing Development Software</span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 11s</span>
                                </div>
                            </a>
                        </li>
                        <!-- List group item -->
                        <li class="list-group-item ps-0">
                            <a href="#" class="d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
                        class="mdi mdi-play fs-4"></i></span>
                                    <span>Hello World Project from GitHub</span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 33s</span>
                                </div>
                            </a>
                        </li>
                        <!-- List group item -->
                        <li class="list-group-item ps-0">
                            <a href="#" class="d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
                        class="mdi mdi-play fs-4"></i></span>
                                    <span>Our Sample Javascript Files</span>
                                </div>
                                <div class="text-truncate">
                                    <span>22m 30s</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


    <!-- new chat modal-->


    <!-- Modal -->
    <div class="modal fade" id="newchatModal" tabindex="-1" role="dialog" aria-labelledby="newchatModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered " role="document">
            <div class="modal-content ">
                <div class="modal-header align-items-center">
                    <h4 class="mb-0" id="newchatModalLabel">Create New Chat</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

                   </button>
                </div>
                <div class="modal-body px-0">
                    <!-- contact list -->
                    <ul class="list-unstyled contacts-list mb-0">
                        <!-- chat item -->
                        <li class="py-3 px-4 chat-item contacts-item">
                            <div class="d-flex justify-content-between align-items-center">
                                <a href="#" class="text-link contacts-link">
                                    <!-- media -->
                                    <div class="d-flex">
                                        <div class="avatar avatar-md avatar-indicators avatar-away">
                                            <img src="../../assets/images/avatar/avatar-5.jpg" alt="" class="rounded-circle">
                                        </div>
                                        <!-- media body -->
                                        <div class=" ms-2">
                                            <h5 class="mb-0">Pete Martin</h5>
                                            <p class="mb-0 text-muted">On going description of group...
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <div>
                                    <small class="text-muted">2/10/2021</small>
                                </div>
                            </div>


                        </li>
                        <!-- chat item -->
                        <li class="py-3 px-4 chat-item contacts-item">

                            <div class="d-flex justify-content-between align-items-center">
                                <a href="#" class="text-link contacts-link">
                                    <!-- media -->
                                    <div class="d-flex">
                                        <div class="avatar avatar-md avatar-indicators avatar-offline">
                                            <img src="../../assets/images/avatar/avatar-9.jpg" alt="" class="rounded-circle">
                                        </div>
                                        <!-- media body -->
                                        <div class=" ms-2">
                                            <h5 class="mb-0">Olivia Cooper</h5>
                                            <p class="mb-0 text-muted">On going description of group...
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <div>
                                    <small class="text-muted">2/3/2021</small>
                                </div>
                            </div>


                        </li>
                        <!-- chat item -->
                        <li class="py-3 px-4 chat-item contacts-item">

                            <div class="d-flex justify-content-between align-items-center">
                                <a href="#" class="text-link contacts-link">
                                    <!-- media -->
                                    <div class="d-flex">
                                        <div class="avatar avatar-md avatar-indicators avatar-busy">
                                            <img src="../../assets/images/avatar/avatar-19.jpg" alt="" class="rounded-circle">
                                        </div>
                                        <!-- media body -->
                                        <div class=" ms-2">
                                            <h5 class="mb-0">Jamarcus Streich</h5>
                                            <p class="mb-0 text-muted">Start design system for UI.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <div>
                                    <small class="text-muted">1/24/2021</small>
                                </div>
                            </div>


                        </li>
                        <!-- chat item -->
                        <li class="py-3 px-4 chat-item contacts-item">

                            <div class="d-flex justify-content-between align-items-center">
                                <a href="#" class="text-link contacts-link">
                                    <!-- media -->
                                    <div class="d-flex">
                                        <div class="avatar avatar-md avatar-indicators avatar-busy">
                                            <img src="../../assets/images/avatar/avatar-12.jpg" alt="" class="rounded-circle">
                                        </div>
                                        <!-- media body -->
                                        <div class=" ms-2">
                                            <h5 class="mb-0">Lauren Wilson</h5>
                                            <p class="mb-0 text-muted">Start design system for UI...
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <div>
                                    <small class="text-muted">3/3/2021</small>
                                </div>
                            </div>


                        </li>
                        <!-- chat item -->
                        <li class="py-3 px-4 chat-item contacts-item">

                            <div class="d-flex justify-content-between align-items-center">
                                <a href="#" class="text-link contacts-link">
                                    <!-- media -->
                                    <div class="d-flex">
                                        <div class="avatar avatar-md avatar-indicators avatar-online">
                                            <img src="../../assets/images/avatar/avatar-14.jpg" alt="" class="rounded-circle">
                                        </div>
                                        <!-- media body -->
                                        <div class=" ms-2">
                                            <h5 class="mb-0">User Name</h5>
                                            <p class="mb-0 text-muted">On going description of group..
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <div>
                                    <small class="text-muted">1/5/2021</small>
                                </div>
                            </div>


                        </li>
                        <!-- chat item -->
                        <li class="py-3 px-4 chat-item contacts-item">

                            <div class="d-flex justify-content-between align-items-center">
                                <a href="#" class="text-link contacts-link">
                                    <!-- media -->
                                    <div class="d-flex">
                                        <div class="avatar avatar-md avatar-indicators avatar-online">
                                            <img src="../../assets/images/avatar/avatar-15.jpg" alt="" class="rounded-circle">
                                        </div>
                                        <!-- media body -->
                                        <div class=" ms-2">
                                            <h5 class="mb-0">Rosalee Roberts</h5>
                                            <p class="mb-0 text-muted">On going description of group..
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <div>
                                    <small class="text-muted">1/14/2021</small>
                                </div>
                            </div>


                        </li>



                    </ul>
                </div>

            </div>
        </div>
    </div>



    <!-- add task -->


    <!-- Modal -->
    <div class="modal fade" id="taskModal" tabindex="-1" role="dialog" aria-labelledby="taskModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="taskModalLabel">Create New Task</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      <i class="fe fe-x-circle"></i>
                  </button>
                </div>
                <div class="modal-body">
                    <form class="row">
                        <div class="mb-2 col-12">
                            <label for="taskTitle" class="form-label">Title</label>
                            <input type="text" class="form-control" id="taskTitle" placeholder="Title" required>
                        </div>
                        <div class="col-6">
                            <label for="priority" class="form-label">Priority</label>
                            <select class="selectpicker" data-width="100%" id="priority">
                              <option selected>Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>

                          </select>
                        </div>
                        <div class="mb-2 col-6">
                            <label for="date" class="form-label">Due Date</label>
                            <input class="form-control flatpickr" type="text" placeholder="Select Date" id="date" required>
                        </div>
                        <div class="mb-2 col-12">
                            <label for="descriptions" class="form-label">Descriptions</label>
                            <textarea class="form-control" id="descriptions" rows="3" required></textarea>
                        </div>
                        <div class="col-12 mb-3">
                            <label for="assignTo" class="form-label">Assign To</label>
                            <select class="selectpicker" id="assignTo" data-width="100%">
                              <option selected>Codescandy</option>
                              <option value="John Deo">John Deo</option>
                              <option value="Misty">Misty</option>
                              <option value="Simon Ray">Simon Ray</option>

                          </select>
                        </div>



                        <div class="col-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-outline-secondary
                              me-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" class="btn btn-primary">Create
                              Task</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

    <!-- footer -->
    <!-- footer -->
    <div class="pt-lg-10 pt-5 footer bg-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <!-- about company -->
                    <div class="mb-4">
                        <!-- <img src="../../assets/images/brand/logo/logo.svg" alt=""> -->
                        <div class="mt-4">
                            <p>Learn2Build</p>
                            <!-- social media -->
                            <!-- <div class="fs-4 mt-4">
                                <a href="#" class="mdi mdi-facebook text-muted me-2"></a>
                                <a href="#" class="mdi mdi-twitter text-muted me-2"></a>
                                <a href="#" class="mdi mdi-instagram text-muted "></a>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="offset-lg-1 col-lg-2 col-md-3 col-6">
                    <div class="mb-4">
                        <!-- list -->
                        <!-- <h3 class="fw-bold mb-3">Company</h3>
                        <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
                            <li><a href="#" class="nav-link">About</a></li>
                            <li><a href="#" class="nav-link">Pricing</a></li>
                            <li><a href="#" class="nav-link">Blog</a></li>
                            <li><a href="#" class="nav-link">Careers</a></li>
                            <li><a href="#" class="nav-link">Contact</a></li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-6">
                    <div class="mb-4">
                        <!-- list -->
                        <!-- <h3 class="fw-bold mb-3">Support</h3>
                        <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
                            <li><a href="#" class="nav-link">Help and Support</a></li>
                            <li><a href="#" class="nav-link">Become Instructor</a></li>
                            <li><a href="#" class="nav-link">Get the app</a></li>
                            <li><a href="#" class="nav-link">FAQ’s</a></li>
                            <li><a href="#" class="nav-link">Tutorial</a></li>
                        </ul> -->

                    </div>
                </div>
                <div class="col-lg-3 col-md-12">
                    <!-- contact info -->
                    <div class="mb-4">
                        <h3 class="fw-bold mb-3">Get in touch</h3>
                        <p>7, Jothi Nagar 1st main road, Annanur, Chennai - 600062</p>
                        <p class="mb-1">Email: <a href="#">naresh@spinsoft.in</a></p>
                        <p>Whatsapp: <span class="text-dark fw-semi-bold">+91-9994194773</span></p>

                    </div>
                </div>
            </div>
            <div class="row align-items-center g-0 border-top py-2 mt-6">
                <!-- Desc -->
                <div class="col-lg-4 col-md-5 col-12">
                    <span>© 2021 Learn2Build, Inc. All Rights Reserved</span>
                </div>

                <!-- Links -->
                <!-- <div class="col-12 col-md-7 col-lg-8 d-md-flex justify-content-end">
                    <nav class="nav nav-footer">
                        <a class="nav-link ps-0" href="#">Privacy Policy</a>
                        <a class="nav-link px-2 px-md-3" href="#">Cookie Notice  </a>
                        <a class="nav-link d-none d-lg-block" href="#">Do Not Sell My Personal Information </a>
                        <a class="nav-link" href="#">Terms of Use</a>
                    </nav>
                </div> -->
            </div>
        </div>
    </div>
</div>
