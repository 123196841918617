<div class="container m-3">
    <div class="row mb-4">
        <div class="col">
            <h2 class="mb-0">Slides</h2>
        </div>
    </div>
    <div class="row" *ngIf="slides">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let slide of slides ">
            <!-- Card -->
            <div class="card mb-4 card-hover">
                <div class="d-flex justify-content-between align-items-center p-4">
                    <div class="d-flex">
                        <!--<a routerLink="/blog/{{slide.category}}">
                            
                             <img src="../assets/images/path/{{getCourseLogo(slide.course_name)}}" alt="" class="avatar-md" /></a> -->
                        <div class="ms-3">
                            <h4 class="mb-1">
                                <a routerLink="{{slide.course_title}}" class="text-inherit">
                  {{slide.course_title}}
                </a>
                            </h4>
                            <p class="mb-0 fs-6">
                                <span class="me-2"><span class="text-dark fw-medium">{{slide.slides.length}}</span> Slides
                                </span>
                                <!-- <span><span class="text-dark fw-medium">34 </span> Hours
                                </span> -->
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
