<div class="col" *ngIf="course">
    <div class="
              card
              mb-4
              card-hover
              rounded
              border border-2 border-secondary
            " ngClass="{{course.percentage > 80 ? 'bg-success' : ''
            }}">
        <div class="progress position-absolute w-100 h-100" style="z-index: 1">
            <div class="progress-bar" [ngClass]="getPercentageClass(course.percentage)" role="progressbar" [ngStyle]="getPercentageStyle(course.percentage)" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <div class="d-flex justify-content-between align-items-center p-4" style="z-index: 2">
            <div class="d-flex">
                <div class="ms-3">
                    <h4 class="mb-1">
                        <a class="text-inherit" routerLink="/course/{{ course.code }}">
                  {{course.code}}  -  {{ course.title }} <span *ngIf="course.percentage">({{ course.percentage }} %) </span>
                    </a>
                    </h4>
                    <p class="mb-0 fs-6">
                        <span class="me-2"><span class="text-dark fw-medium">{{ course.noOfTopics }} Topics</span>
                        </span>
                        <!-- <span><span class="text-dark fw-medium">{{getPercentage(course.code)}} </span> %
                                          </span> -->
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>