<div class="container m-3">
    <div class="row mb-4">
        <div class="col">
            <h2 class="mb-0">Courses</h2>
        </div>
    </div>
    <div class="position-relative">
        <ul class="controls " id="sliderFirstControls">
            <li class="prev">
                <i class="fe fe-chevron-left"></i>
            </li>
            <li class="next">
                <i class="fe fe-chevron-right"></i>
            </li>
        </ul>
        <div class="sliderFirst row">
            <div class="col-4 item tns-item" *ngFor="let course of courses">
                <!-- Card -->
                <div class="card  mb-4 card-hover">
                    <a routerLink="/courses/{{course.id}}/overview" class="card-img-top">
                        <img src="assets/images/course/{{course.imageUrl}}" alt="" class="rounded-top-md card-img-top"></a>
                    <!-- Card Body -->
                    <div class="card-body">
                        <h4 class="mb-2 text-truncate-line-2 ">
                            <a routerLink="/courses/{{course.id}}/overview" class=" text-inherit ">
                              {{course.title}}</a></h4>
                        <!-- List -->
                        <ul class="mb-3 list-inline ">
                            <li class="list-inline-item "><i class="far fa-clock me-1 "></i>{{course.duration}}</li>
                            <li class="list-inline-item ">
                                <svg class="me-1 mt-n1 " width="16 " height="16 " viewBox="0 0 16 16 " fill="none " xmlns="http://www.w3.org/2000/svg ">
                    <rect x="3 " y="8 " width="2 " height="6 " rx="1 " fill="#754FFE " />
                    <rect x="7 " y="5 " width="2 " height="9 " rx="1 " fill="#DBD8E9 " />
                    <rect x="11 " y="2 " width="2 " height="12 " rx="1 " fill="#DBD8E9 " />
                  </svg> {{course.level}}
                            </li>
                        </ul>
                        <div class="lh-1 ">
                            <span>
                  <i class="mdi mdi-star text-warning me-n1 "></i>
                  <i class="mdi mdi-star text-warning me-n1 "></i>
                  <i class="mdi mdi-star text-warning me-n1 "></i>
                  <i class="mdi mdi-star text-warning me-n1 "></i>
                  <i class="mdi mdi-star text-warning "></i>
                </span>
                            <span class="text-warning ">{{course.rating}}</span>
                            <span class="fs-6 text-muted ">({{course.reviews}})</span>
                        </div>
                        <!-- Price -->
                        <!-- <div class="lh-1 mt-3 ">
                                <span class="text-dark fw-bold ">Rs.{{course.price}}</span>
                                <del class="fs-6 text-muted ">$750</del>
                            </div> -->
                    </div>
                    <!-- Card Footer -->
                    <div class="card-footer ">
                        <div class="row align-items-center g-0 ">
                            <div class="col-auto ">
                                <img src="assets/images/instructor/naresh.png" class="rounded-circle avatar-xs " alt=" ">
                            </div>
                            <div class="col ms-2 ">
                                <span>{{course.authorName}}</span>
                            </div>
                            <div class="col-auto ">
                                <a href="# " class="text-muted bookmark ">
                                    <i class="fe fe-bookmark "></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
