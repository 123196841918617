<!-- Card -->
<div class="card mb-3 mb-4">
    <div class="p-1">
        <div class="d-flex justify-content-center position-relative rounded py-10 border-white border rounded-3 bg-cover" style="background-image: url(../assets/images/course/course-javascript.jpg);">
            <a class="popup-youtube icon-shape rounded-circle btn-play icon-xl text-decoration-none" href="https://www.youtube.com/watch?v=JRzWRZahOVU">
                <i class="fe fe-play"></i>
            </a>
        </div>
    </div>
    <!-- Card body -->
    <div class="card-body">
        <!-- Price single page -->
        <div class="mb-3">
            <span class="text-dark fw-bold h2">Level 1</span>
            <!-- <span class="text-dark fw-bold h2">$600</span>
            <del class="fs-4 text-muted">$750</del> -->
        </div>
        <div class="d-grid">
            <a routerLink="../content" class="btn btn-primary mb-2  ">View Course</a>
            <!-- <a href="#" class="btn btn-primary mb-2  ">Start Free Month</a>
            <a href="pricing.html" class="btn btn-outline-primary">Get Full Access</a> -->
        </div>
    </div>
</div>
<!-- Card -->
<div class="card mb-4">
    <div>
        <!-- Card header -->
        <div class="card-header">
            <h4 class="mb-0">What’s included</h4>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item bg-transparent"><i class="fe fe-play-circle align-middle me-2 text-primary"></i>12 hours video</li>
            <li class="list-group-item bg-transparent"><i class="fe fe-award me-2 align-middle text-success"></i>Certificate</li>
            <li class="list-group-item bg-transparent"><i class="fe fe-calendar align-middle me-2 text-info"></i>12 Article
            </li>
            <!-- <li class="list-group-item bg-transparent"><i class="fe fe-video align-middle me-2 text-secondary"></i>Watch Offline</li> -->
            <!-- <li class="list-group-item bg-transparent border-bottom-0"><i class="fe fe-clock align-middle me-2 text-warning"></i>Lifetime access</li> -->
        </ul>
    </div>
</div>
<!-- Card -->
<app-course-instructor></app-course-instructor>
