<div class="tab-pane fade" id="description" role="tabpanel" aria-labelledby="description-tab">
    <!-- Description -->
    <div class="mb-4">
        <h3 class="mb-2">Course Descriptions</h3>
        <p>
            If you’re learning to program for the first time, or if you’re coming from a different language, this course, JavaScript: Getting Started, will give you the basics for coding in JavaScript. First, you'll discover the types of applications that can be
            built with JavaScript, and the platforms they’ll run on.
        </p>
        <p>
            Next, you’ll explore the basics of the language, giving plenty of examples. Lastly, you’ll put your JavaScript knowledge to work and modify a modern, responsive web page. When you’re finished with this course, you’ll have the skills and knowledge in JavaScript
            to create simple programs, create simple web applications, and modify web pages.
        </p>
    </div>
    <h4 class="mb-3">What you’ll learn</h4>
    <div class="row mb-3">
        <div class="col-12 col-md-6">
            <ul class="list-unstyled">
                <li class="d-flex mb-2">
                    <i class="far fa-check-circle text-success me-2 mt-2"></i>
                    <span>Recognize the importance of understanding your objectives when addressing an
audience.</span>
                </li>
                <li class="d-flex mb-2">
                    <i class="far fa-check-circle text-success me-2 mt-2"></i>
                    <span>Identify the fundaments of composing a successful close.</span>
                </li>
                <li class="d-flex mb-2">
                    <i class="far fa-check-circle text-success me-2 mt-2"></i>
                    <span>Explore how to connect with your audience through crafting compelling stories.</span>
                </li>
            </ul>
        </div>
        <div class="col-12 col-md-6">
            <ul class="list-unstyled">
                <li class="d-flex mb-2">
                    <i class="far fa-check-circle text-success me-2 mt-2"></i>
                    <span>Examine ways to connect with your audience by personalizing your content.</span>
                </li>
                <li class="d-flex mb-2">
                    <i class="far fa-check-circle text-success me-2 mt-2"></i>
                    <span>Break down the best ways to exude executive presence.</span>
                </li>
                <li class="d-flex mb-2">
                    <i class="far fa-check-circle text-success me-2 mt-2"></i>
                    <span>Explore how to communicate the unknown in an impromptu communication.</span>
                </li>
            </ul>
        </div>
    </div>
    <p>Maecenas viverra condimentum nulla molestie condimentum. Nunc ex libero, feugiat quis lectus vel, ornare euismod ligula. Aenean sit amet arcu nulla.</p>
    <p>Duis facilisis ex a urna blandit ultricies. Nullam sagittis ligula non eros semper, nec mattis odio ullamcorper. Phasellus feugiat sit amet leo eget consectetur.</p>
</div>