<div class="container my-3">
    <div class="row mb-4">
        <div class="col">
            <h2 class="mb-0">Projects</h2>
        </div>
        <div class="col pull-right">
            <form>
                <input type="search" name="search" [(ngModel)]="searchProject" placeholder="Search Project" autocomplete="off">
            </form>
        </div>
    </div>
    <div class="row" *ngIf="projects">

        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let project of (projects | projectSearch: searchProject)">
            <!-- Card -->
            <div class="card mb-4 card-hover">
                <div class="d-flex justify-content-between align-items-center p-4">
                    <div class="d-flex">



                        <div class="ms-3">
                            <h4 class="mb-1">
                                <a routerLink="/projects/{{project._id}}" class="text-inherit">
                {{project.name}}
              </a>
                            </h4>
                            <p class="mb-0 fs-6">
                                <span class="me-2"><span class="text-dark fw-medium">{{project.modules.length}} Modules</span>
                                </span>
                                <span><span class="text-dark fw-medium">{{getFeatures(project)}} Features</span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
