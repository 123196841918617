<div class="mt-3 course-container">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12">



                <!-- Tab content -->
                <div class="tab-content content" id="course-tabContent">
                    <h3 class="text-center">{{category | uppercase}}</h3>
                    <div *ngIf="selectedArticle">
                        <app-view-article [article]="selectedArticle"></app-view-article>
                    </div>

                </div>
            </div>
        </div>
        <!-- Card -->
        <div class="card course-sidebar " id="courseAccordion">
            <!-- List group -->
            <ul class="list-group list-group-flush course-list">
                <li class="list-group-item">
                    <h4 class="mb-0">
                        <a routerLink="../">
                            <i class="fas fa-arrow-left    "></i> {{category}} - Table of Content </a>
                    </h4>
                </li>
                <!-- List group item -->
                <li class="list-group-item" *ngFor="let section of sections | keyvalue;let i=index;">
                    <!-- Toggle -->
                    <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseTwo" role="button" aria-expanded="false" aria-controls="courseTwo">
                        <div class="me-auto">
                            {{section.key}}
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse show" id="courseTwo" data-bs-parent="#courseAccordion">

                        <div class="py-4 nav" id="course-tabOne" role="tablist" aria-orientation="vertical" style="display: inherit;">
                            <ng-container *ngFor="let article of getArticles(section.key)">

                                <a  class="{{article.title==selectedArticle.title?'article-active':''}} article-title mb-2 d-flex justify-content-between align-items-center text-decoration-none" id="course-intro-tab" data-bs-toggle="pill" (click)="onChange(article)" role="tab" aria-controls="course-intro"
                                    aria-selected="true">
                                    <div class="text-truncate">
                                        <span class="icon-shape bg-light text-primary icon-sm  rounded-circle me-2"><i
                        class="fe fe-play  fs-6"></i></span>
                                        <span>{{article.title}}</span>
                                    </div>
                                    <div class="text-truncate">
                                        <span>{{article.duration}} mins</span>
                                    </div>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    </div>