<div class="mt-13 course-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Tab content -->
                <div class="tab-content content" id="course-tabContent">
                    <div class="tab-pane fade show active" id="course-intro" role="tabpanel" aria-labelledby="course-intro-tab">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h3 class=" mb-0  text-truncate-line-2">Introduction </h3>
                            </div>
                            <div>
                                <!-- Dropdown -->
                                <span class="dropdown">
                    <a href="#" class="ms-2 text-muted" id="dropdownInfo" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fe fe-help-circle"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-lg p-3 dropdown-menu-end" aria-labelledby="dropdownInfo">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      cupiditate consequatur rerum eius ad ut officiis
                    </span>
                                </span>
                                <!-- Dropdown -->
                                <span class="dropdown">
                    <a class="text-muted text-decoration-none" href="#" role="button" id="shareDropdown1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fe fe-more-vertical"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-end" aria-labelledby="shareDropdown1">
                      <span class="dropdown-header">Share</span>
                                <a class="dropdown-item" href="#"><i class="fas fa-copy dropdown-item-icon"></i>Copy Link</a>
                                </span>
                                </span>
                            </div>
                        </div>
                        <!-- Video -->
                        <div class="embed-responsive  position-relative w-100 d-block overflow-hidden p-0" style="height: 600px;">
                            <iframe class="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100" src="https://www.youtube.com/embed/PkZNo7MFNFg"></iframe>
                        </div>
                    </div>
                    <!-- Tab pane -->
                    <div class="tab-pane fade" id="course-development" role="tabpanel" aria-labelledby="course-development-tab">
                        <div class="d-lg-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h2 class="h3 mb-0">Installing Development Software</h2>
                            </div>
                            <div>
                                <!-- Dropdown -->
                                <span class="dropdown">
                    <a href="#" class="ms-2 text-muted" id="dropdownInfo2" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="fe fe-help-circle"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-lg p-3" aria-labelledby="dropdownInfo2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      cupiditate consequatur rerum eius ad ut officiis
                    </span>
                                </span>
                                <!-- Dropdown -->
                                <span class="dropdown ">
                    <a class="text-muted text-decoration-none" href="#" role="button" id="shareDropdown2"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fe fe-more-vertical"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-end" aria-labelledby="shareDropdown2">
                      <span class="dropdown-header">Share</span>
                                <a class="dropdown-item" href="#"><i class="fab fa-facebook dropdown-item-icon"></i>Facebook</a>
                                <a class="dropdown-item" href="#"><i class="fab fa-twitter dropdown-item-icon"></i>Twitter</a>
                                <a class="dropdown-item" href="#"><i class="fab fa-linkedin dropdown-item-icon"></i>Linked In</a>
                                <a class="dropdown-item" href="#"><i class="fas fa-copy dropdown-item-icon"></i>Copy Link</a>
                                </span>
                                </span>
                            </div>
                        </div>
                        <!-- Video -->
                        <div class="embed-responsive  position-relative w-100 d-block overflow-hidden p-0" style="height: 600px;">
                            <iframe class="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100" src="https://www.youtube.com/embed/PkZNo7MFNFg"></iframe>
                        </div>
                    </div>
                    <!-- Tab pane -->
                    <div class="tab-pane fade" id="course-project" role="tabpanel" aria-labelledby="course-project-tab">
                        <div class="d-lg-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h2 class="h3 mb-0">Hello World Project from GitHub</h2>
                            </div>
                            <div>
                                <!-- Dropdown -->
                                <span class="dropdown">
                    <a href="#" class="ms-2 text-muted" id="dropdownInfo3" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="fe fe-help-circle"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-lg p-3" aria-labelledby="dropdownInfo3">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      cupiditate consequatur rerum eius ad ut officiis
                    </span>
                                </span>
                                <!-- Dropdown -->
                                <span class="dropdown ">
                    <a class="text-muted text-decoration-none" href="#" role="button" id="shareDropdown3"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fe fe-more-vertical"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-end" aria-labelledby="shareDropdown3">
                      <span class="dropdown-header">Share</span>
                                <a class="dropdown-item" href="#"><i class="fab fa-facebook dropdown-item-icon"></i>Facebook</a>
                                <a class="dropdown-item" href="#"><i class="fab fa-twitter dropdown-item-icon"></i>Twitter</a>
                                <a class="dropdown-item" href="#"><i class="fab fa-linkedin dropdown-item-icon"></i>Linked In</a>
                                <a class="dropdown-item" href="#"><i class="fas fa-copy dropdown-item-icon"></i>Copy Link</a>
                                </span>
                                </span>
                            </div>
                        </div>
                        <!-- Video -->
                        <div class="embed-responsive  position-relative w-100 d-block overflow-hidden p-0" style="height: 600px;">
                            <iframe class="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100" src="https://www.youtube.com/embed/PkZNo7MFNFg"></iframe>
                        </div>
                    </div>
                    <!-- Tab pane -->
                    <div class="tab-pane fade" id="course-website" role="tabpanel" aria-labelledby="course-website-tab">
                        <div class="d-lg-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h2 class="h3 mb-0">Our Sample Website</h2>
                            </div>
                            <div>
                                <!-- Dropdown -->
                                <span class="dropdown">
                    <a href="#" class="ms-2 text-muted" id="dropdownInfo4" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="fe fe-help-circle"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-lg p-3" aria-labelledby="dropdownInfo4">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      cupiditate consequatur rerum eius ad ut officiis
                    </span>
                                </span>
                                <!-- Dropdown -->
                                <span class="dropdown ">
                    <a class="text-muted text-decoration-none" href="#" role="button" id="shareDropdown4"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fe fe-more-vertical"></i>
                    </a>
                    <span class="dropdown-menu dropdown-menu-end" aria-labelledby="shareDropdown4">
                      <span class="dropdown-header">Share</span>
                                <a class="dropdown-item" href="#"><i class="fab fa-facebook dropdown-item-icon"></i>Facebook</a>
                                <a class="dropdown-item" href="#"><i class="fab fa-twitter dropdown-item-icon"></i>Twitter</a>
                                <a class="dropdown-item" href="#"><i class="fab fa-linkedin dropdown-item-icon"></i>Linked In</a>
                                <a class="dropdown-item" href="#"><i class="fas fa-copy dropdown-item-icon"></i>Copy Link</a>
                                </span>
                                </span>
                            </div>
                        </div>
                        <!-- Video -->
                        <div class="embed-responsive  position-relative w-100 d-block overflow-hidden p-0" style="height: 600px;">
                            <iframe class="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100" src="https://www.youtube.com/embed/PkZNo7MFNFg"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Card -->
<div class="card course-sidebar " id="courseAccordion">
    <!-- List group -->
    <ul class="list-group list-group-flush course-list">
        <li class="list-group-item">
            <h4 class="mb-0">Table of Content</h4>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseTwo" role="button" aria-expanded="false" aria-controls="courseTwo">
                <div class="me-auto">
                    Introduction to JavaScript
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse show" id="courseTwo" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabOne" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-decoration-none" id="course-intro-tab" data-bs-toggle="pill" href="#course-intro" role="tab" aria-controls="course-intro" aria-selected="true">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-primary icon-sm  rounded-circle me-2"><i
                      class="fe fe-play  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 7s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none" id="course-development-tab" data-bs-toggle="pill" href="#course-development" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-primary icon-sm  rounded-circle me-2"><i
                      class="fe fe-play  fs-6"></i></span>
                            <span>Installing Development Software</span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 11s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none" id="course-project-tab" data-bs-toggle="pill" href="#course-project" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-primary icon-sm  rounded-circle me-2"><i
                      class="fe fe-play  fs-6"></i></span>
                            <span>Hello World Project from GitHub</span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 33s</span>
                        </div>
                    </a>
                    <a class="d-flex justify-content-between align-items-center text-inherit text-decoration-none" id="course-website-tab" data-bs-toggle="pill" href="#course-website" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-primary icon-sm  rounded-circle me-2"><i
                      class="fe fe-play  fs-6"></i></span>
                            <span>Our Sample Website</span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 15s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseThree" role="button" aria-expanded="false" aria-controls="courseThree">
                <div class="me-auto">
                    <!-- Title -->
                    JavaScript Beginning
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse" id="courseThree" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabTwo" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab2" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="true">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 41s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab2" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Adding JavaScript Code to a Web Page</span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 39s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab2" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span> Working with JavaScript Files </span>
                        </div>
                        <div class="text-truncate">
                            <span>6m 18s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab2" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Formatting Code </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 18s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab3" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span> Detecting and Fixing Errors </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 14s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab3" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Case Sensitivity </span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 48s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab3" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Commenting Code </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 24s</span>
                        </div>
                    </a>
                    <a class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab3" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Summary</span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 14s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseFour" role="button" aria-expanded="false" aria-controls="courseFour">
                <div class="me-auto">
                    <!-- Title -->
                    Variables and Constants
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse" id="courseFour" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabThree" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab4" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 19s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab4" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>What Is a Variable?</span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 11s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab5" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Declaring Variables </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 30s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab4" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Using let to Declare Variables </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 28s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab5" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Naming Variables </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 14s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab6" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Common Errors Using Variables </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 30s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab4" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Changing Variable Values </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 4s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab19" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Constants </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 15s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab5" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>The var Keyword </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 20s</span>
                        </div>
                    </a>
                    <a class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab7" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Summary</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 49s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseSix" role="button" aria-expanded="false" aria-controls="courseSix">
                <div class="me-auto">
                    <!-- Title -->
                    Program Flow
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse" id="courseSix" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabFour" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab8" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 52s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab6" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Clip Watched </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 27s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab5" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Conditionals Using if() </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 25s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab6" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Truthy and Falsy</span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 30s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab7" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>if ... else </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 30s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab9" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Comparing === and == </span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 52s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab7" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>The Ternary Operator </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 47s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab10" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Block Scope Using let </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 21s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab8" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Looping with for() </span>
                        </div>
                        <div class="text-truncate">
                            <span>5m 30s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab8" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Looping with do ... while() </span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 58s</span>
                        </div>
                    </a>
                    <a class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab6" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Summary </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 21s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseSeven" role="button" aria-expanded="false" aria-controls="courseSeven">
                <div class="me-auto">
                    <!-- Title -->
                    Functions
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse" id="courseSeven" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabFive" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab11" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 52s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab11" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Function Basics </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 46s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab7" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Function Expressions </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 32s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab12" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Passing Information to Functions</span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 19s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab9" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Function Return Values </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 13s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab8" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Function Scope </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 20s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab12" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Using Functions to Modify Web Pages </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 42s</span>
                        </div>
                    </a>
                    <a class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab10" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Summary </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 3s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0 justify-content-between" data-bs-toggle="collapse" href="#courseEight" role="button" aria-expanded="false" aria-controls="courseEight">
                <div class="me-auto">
                    <!-- Title -->
                    Objects and the DOM
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>

            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse" id="courseEight" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabSix" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab13" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 48s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab13" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Object Properties </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 28s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab11" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Object Methods </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 3s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab9" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Passing Objects to Functions</span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 27s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab14" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Standard Built-in Objects </span>
                        </div>
                        <div class="text-truncate">
                            <span>6m 55s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab12" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>The Document Object Model (DOM) </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 29s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab14" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Styling DOM Elements </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 42s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab10" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Detecting Button Clicks </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 3s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab13" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Showing and Hiding DOM Elements </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 37s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab15" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Summary </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 47s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseNine" role="button" aria-expanded="false" aria-controls="courseNine">
                <div class="me-auto">
                    <!-- Title -->
                    Arrays
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse" id="courseNine" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabSeven" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab16" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 48s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab14" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Creating and Initializing Arrays </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 7s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab15" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Accessing Array Items </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 4s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab11" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Manipulating Arrays </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 3s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab15" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>slice() and splice() </span>
                        </div>
                        <div class="text-truncate">
                            <span>5m 54s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab17" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Array Searching and Looping </span>
                        </div>
                        <div class="text-truncate">
                            <span>7m 32s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab16" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Arrays in the DOM </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 11s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab18" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Summary </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 28s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0 " data-bs-toggle="collapse" href="#courseTen" role="button" aria-expanded="false" aria-controls="courseTen">
                <div class="me-auto">
                    <!-- Title -->
                    Scope and Hoisting
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- Row -->
            <!-- Collapse -->
            <div class="collapse" id="courseTen" data-bs-parent="#courseAccordion">
                <div class="py-4 nav" id="course-tabEight" role="tablist" aria-orientation="vertical" style="display: inherit;">
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-intro-tab19" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-intro" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Introduction</span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 20s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab16" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Global Scope </span>
                        </div>
                        <div class="text-truncate">
                            <span>4m 7s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab17" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Clip Watched </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 14s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-development-tab12" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-development" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Function Scope </span>
                        </div>
                        <div class="text-truncate">
                            <span>3m 45s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab17" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Var and Hoisting </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 21s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-website-tab18" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-website" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Undeclared Variables and Strict Mode </span>
                        </div>
                        <div class="text-truncate">
                            <span>2m 16s</span>
                        </div>
                    </a>
                    <a class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick" id="course-project-tab18" data-bs-toggle="pill" href="#" role="tab" aria-controls="course-project" aria-selected="false">
                        <div class="text-truncate">
                            <span class="icon-shape bg-light text-secondary icon-sm  rounded-circle me-2"><i
                      class="fe fe-lock  fs-6"></i></span>
                            <span>Summary </span>
                        </div>
                        <div class="text-truncate">
                            <span>1m 33s</span>
                        </div>
                    </a>
                </div>
            </div>
        </li>
        <!-- List group item -->
        <li class="list-group-item">
            <!-- Toggle -->
            <a class="d-flex align-items-center text-inherit text-decoration-none h4 mb-0" data-bs-toggle="collapse" href="#courseEleven" role="button" aria-expanded="false" aria-controls="courseEleven">
                <div class="me-auto">
                    <!-- Title -->
                    Summary
                </div>
                <!-- Chevron -->
                <span class="chevron-arrow  ms-4">
              <i class="fe fe-chevron-down fs-4"></i>
            </span>
            </a>
            <!-- / .row -->
            <!-- Collapse -->
            <div class="collapse" id="courseEleven" data-bs-parent="#courseAccordion">
                <div class="py-4">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae esse velit eos sunt ab inventore est tenetur blanditiis? Voluptas eius molestiae ad itaque tempora nobis minima eveniet aperiam molestias. </p>
                    <p>Maiores natus expedita dolores ea non possimus magnam corrupt i quas rem unde quo enim porro culpa! Quaerat veritatis veniam corrupti iusto.</p>
                </div>
            </div>
        </li>
    </ul>
</div>