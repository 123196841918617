<div class="tab-pane fade show active" id="table" role="tabpanel" aria-labelledby="table-tab">
    <!-- Card -->
    <div class="accordion" id="courseAccordion">
        <div>
            <!-- List group -->
            <ul class="list-group list-group-flush">
                <li class="list-group-item px-0 pt-0">
                    <!-- Toggle -->
                    <a class=" h4 mb-0 d-flex align-items-center text-inherit text-decoration-none active" data-bs-toggle="collapse" href="#courseTwo" aria-expanded="true" aria-controls="courseTwo">
                        <div class="me-auto">
                            Introduction to JavaScript
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse show" id="courseTwo" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 7s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Installing Development Software</span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 11s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Hello World Project from GitHub</span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 33s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Our Sample Website</span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 15s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseThree" aria-expanded="false" aria-controls="courseThree">
                        <div class="me-auto">
                            <!-- Title -->
                            JavaScript Beginning
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseThree" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 41s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Adding JavaScript Code to a Web Page</span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 39s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>
Working with JavaScript Files
</span>
                                </div>
                                <div class="text-truncate">
                                    <span>6m 18s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Formatting Code </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 18s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>
Detecting and Fixing Errors
</span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 14s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Case Sensitivity </span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 48s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Commenting Code </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 24s</span>
                                </div>
                            </a>
                            <a href="course-resume.html" class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
  class="mdi mdi-play fs-4"></i></span>
                                    <span>Summary</span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 14s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseFour" aria-expanded="false" aria-controls="courseFour">
                        <div class="me-auto">
                            <!-- Title -->
                            Variables and Constants
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseFour" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 19s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>What Is a Variable?</span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 11s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Declaring Variables </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 30s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Using let to Declare Variables </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 28s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Naming Variables </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 14s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Common Errors Using Variables </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 30s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Changing Variable Values </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 4s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Constants </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 15s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>The var Keyword </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 20s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Summary</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 49s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseFive" aria-expanded="false" aria-controls="courseFive">
                        <div class="me-auto">
                            <!-- Title -->
                            Types and Operators
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseFive" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 55s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Numbers </span>
                                </div>
                                <div class="text-truncate">
                                    <span>6m 14s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Operator Precedence </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 58s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Number Precision</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 22s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Negative Numbers </span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 35s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Strings </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 7s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Manipulating Strings </span>
                                </div>
                                <div class="text-truncate">
                                    <span>5m 8s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Converting Strings and Numbers </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 55s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Boolean Variables </span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 39s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseSix" aria-expanded="false" aria-controls="courseSix">
                        <div class="me-auto">
                            <!-- Title -->
                            Program Flow
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseSix" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 52s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Clip Watched </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 27s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Conditionals Using if() </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 25s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Truthy and Falsy</span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 30s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>if ... else </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 30s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Comparing === and == </span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 52s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>The Ternary Operator </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 47s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Block Scope Using let </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 21s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Looping with for() </span>
                                </div>
                                <div class="text-truncate">
                                    <span>5m 30s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Looping with do ... while() </span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 58s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Summary </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 21s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseSeven" aria-expanded="false" aria-controls="courseSeven">
                        <div class="me-auto">
                            <!-- Title -->
                            Functions
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseSeven" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 52s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Function Basics </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 46s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Function Expressions </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 32s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Passing Information to Functions</span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 19s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Function Return Values </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 13s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Function Scope </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 20s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Using Functions to Modify Web Pages </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 42s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-0 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Summary </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 3s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseEight" aria-expanded="false" aria-controls="courseEight">
                        <div class="me-auto">
                            <!-- Title -->
                            Objects and the DOM
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseEight" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 48s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Object Properties </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 28s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Object Methods </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 3s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Passing Objects to Functions</span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 27s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Standard Built-in Objects </span>
                                </div>
                                <div class="text-truncate">
                                    <span>6m 55s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>The Document Object Model (DOM) </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 29s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Styling DOM Elements </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 42s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Detecting Button Clicks </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 3s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Showing and Hiding DOM Elements </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 37s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Summary </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 47s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseNine" aria-expanded="false" aria-controls="courseNine">
                        <div class="me-auto">
                            <!-- Title -->
                            Arrays
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseNine" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 48s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Creating and Initializing Arrays </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 7s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Accessing Array Items </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 4s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Manipulating Arrays </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 3s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>slice() and splice() </span>
                                </div>
                                <div class="text-truncate">
                                    <span>5m 54s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Array Searching and Looping </span>
                                </div>
                                <div class="text-truncate">
                                    <span>7m 32s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Arrays in the DOM </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 11s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Summary </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 28s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseTen" aria-expanded="false" aria-controls="courseTen">
                        <div class="me-auto">
                            <!-- Title -->
                            Scope and Hoisting
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseTen" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Introduction</span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 20s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Global Scope </span>
                                </div>
                                <div class="text-truncate">
                                    <span>4m 7s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Clip Watched </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 14s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Function Scope </span>
                                </div>
                                <div class="text-truncate">
                                    <span>3m 45s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Var and Hoisting </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 21s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Undeclared Variables and Strict Mode </span>
                                </div>
                                <div class="text-truncate">
                                    <span>2m 16s</span>
                                </div>
                            </a>
                            <a href="#" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none disableClick">
                                <div class="text-truncate">
                                    <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2"><i
  class="fe fe-lock fs-4"></i></span>
                                    <span>Summary </span>
                                </div>
                                <div class="text-truncate">
                                    <span>1m 33s</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <!-- List group item -->
                <li class="list-group-item px-0 pb-0">
                    <!-- Toggle -->
                    <a class="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" href="#courseEleven" aria-expanded="false" aria-controls="courseEleven">
                        <div class="me-auto">
                            <!-- Title -->
                            Summary
                        </div>
                        <!-- Chevron -->
                        <span class="chevron-arrow ms-4">
<i class="fe fe-chevron-down fs-4"></i>
</span>
                    </a>
                    <!-- Row -->
                    <!-- Collapse -->
                    <div class="collapse" id="courseEleven" data-bs-parent="#courseAccordion">
                        <div class="pt-3 pb-2">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae esse velit eos sunt ab inventore est tenetur blanditiis? Voluptas eius molestiae ad itaque tempora nobis minima eveniet aperiam molestias, maiores natus expedita dolores ea non possimus
                                magnam corrupt i quas rem unde quo enim porro culpa! Quaerat veritatis veniam corrupti iusto.
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>