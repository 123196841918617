<div class="card">
    <!-- Card body -->
    <div class="card-body">
        <div class="d-flex align-items-center">
            <div class="position-relative">
                <img src="../assets/images/instructor/naresh.png" alt="" class="rounded-circle avatar-xl" />
                <a href="#" class="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" title="Verifed">
                    <img src="../assets/images/svg/checked-mark.svg" alt="" height="30" width="30" />
                </a>
            </div>
            <div class="ms-4">
                <h4 class="mb-0">Naresh Kumar H</h4>
                <p class="mb-1 fs-6">Full Stack Developer</p>
                <span class="fs-6"><span class="text-warning">4.5</span><span class="mdi mdi-star text-warning me-2"></span>Instructor Rating</span>
            </div>
        </div>
        <div class="border-top row mt-3 border-bottom mb-3 g-0">
            <div class="col">
                <div class="pe-1 ps-2 py-3">
                    <h5 class="mb-0">11,604</h5>
                    <span>Students</span>
                </div>
            </div>
            <div class="col border-start">
                <div class="pe-1 ps-3 py-3">
                    <h5 class="mb-0">32</h5>
                    <span>Courses</span>
                </div>
            </div>
            <div class="col border-start">
                <div class="pe-1 ps-3 py-3">
                    <h5 class="mb-0">12,230</h5>
                    <span>Reviews</span>
                </div>
            </div>
        </div>
        <p>I am a Full Stack Developer based in Chennai. </p>
        <!-- <a href="instructor-profile.html" class="btn btn-outline-white btn-sm">View Details</a> -->
    </div>
</div>