<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-default" style="background-color: rgb(43, 54, 67);color:white">
    <div class=" container-fluid px-0 ">
        <a class="navbar-brand" routerLink="/">

            <img [src]="logoUrl" alt=" " width="30px " /> Learn2Build
        </a>
        <!-- Button -->
        <button class="navbar-toggler collapsed " type="button " data-bs-toggle="collapse " data-bs-target="#navbar-default " aria-controls="navbar-default " aria-expanded="false " aria-label="Toggle navigation ">
    <span class="icon-bar top-bar mt-0 "></span>
    <span class="icon-bar middle-bar "></span>
    <span class="icon-bar bottom-bar "></span>
  </button>
        <!-- Collapse -->
        <div class="collapse navbar-collapse " id="navbar-default ">
            <ul class="navbar-nav ">
                <li class="nav-item ">
                    <a class=" nav-link " routerLink="/home" id="navbarBrowse " aria-haspopup="true ">
          Home
        </a>
                </li>
                <li class="nav-item ">
                    <a class=" nav-link " routerLink="/blog" id="navbarBrowse ">
        Blog
      </a>
                </li>
                <li class="nav-item ">
                    <a class=" nav-link " routerLink="/projects" id="navbarBrowse ">
      Projects
    </a>
        <!--        </li>
                <li class="nav-item " *ngIf="user && authService.hasRole('T')">
                    <a class=" nav-link " routerLink="/users" id="navbarBrowse ">
    Users
  </a>
                </li> 
                <li class="nav-item ">
                    <a class=" nav-link " routerLink="/courses" id="navbarBrowse ">
      Courses
    </a>
                </li> 
                <li class="nav-item ">
                    <a class=" nav-link " routerLink="/specializations" id="navbarBrowse ">
    Specializations
  </a>
                </li> -->
                <!-- <li class="nav-item ">
                    <a class=" nav-link " routerLink="/slides" id="navbarBrowse ">
    Slides
  </a>
                </li> -->
            </ul>

            <ul class="navbar-nav navbar-right-wrap ms-auto d-none d-lg-block">
                <li class="nav-item d-inline-block" *ngIf="!user">
                    <a class=" nav-link " routerLink="auth/login" id="navbarBrowse " aria-haspopup="true ">
        Login
      </a>
                </li>
                <li class="nav-item d-inline-block" *ngIf="!user">
                    <a class=" nav-link " routerLink="auth/register" id="navbarBrowse " aria-haspopup="true ">
      Register
    </a>
                </li>
                <li class="nav-item d-inline-block" *ngIf="user">
                    <a class=" nav-link d-inline-block " routerLink="/users/{{user.username}}" id="navbarBrowse ">
       {{user.name}}

                  </a>
                </li>
                <li class="nav-item d-inline-block" *ngIf="user">
                    <a class=" nav-link d-inline-block " href="#" (click)="logout()" id="navbarBrowse ">

                        <i aria-hidden="true" class="fas fa-sign-out-alt"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>