<div class="container m-3">
    <div class="row mb-4">
        <div class="col">
            <h2 class="mb-0">Blog Articles</h2>
        </div>
    </div>
    <div class="row" *ngIf="articles">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let article of articles">
            <!-- Card -->
            <div class="card mb-4 card-hover">
                <div class="d-flex justify-content-between align-items-center p-4">
                    <div class="d-flex">
                        <a routerLink="/blog/{{article.category}}">
                            <!-- Img -->
                            <img src="../assets/images/path/{{getCourseLogo(article.category)}}" alt="" class="avatar-md" /></a>
                        <div class="ms-3">
                            <h4 class="mb-1">
                                <a routerLink="/blog/{{article.category}}" class="text-inherit">
                  {{article.category}}
                </a>
                            </h4>
                            <p class="mb-0 fs-6">
                                <span class="me-2"><span class="text-dark fw-medium">{{article.cnt}}</span> Articles
                                </span>
                                <!-- <span><span class="text-dark fw-medium">34 </span> Hours
                                </span> -->
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
