<div class="container my-3">
    <div class="row mb-4">
        <div class="col">
            <h2 class="mb-0">Career Specializations
                <span *ngIf="user && user.careerTrack"> - {{user.careerTrack}}</span>
            </h2>
        </div>

    </div>


    <div class="row" *ngIf="specializations">

        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let specialization of specializations">
            <!-- Card -->
            <div class="card mb-4 card-hover
            border border-2 border-secondary
            " [ngClass]="getPercentageClass(specialization)">
                <div class="d-flex justify-content-between align-items-center p-4">
                    <div class="d-flex">



                        <div class="ms-3">
                            <h4 class="mb-1">
                                <a routerLink="/specializations/{{specialization.id}}" class="text-inherit">
              {{specialization.name}} <span *ngIf="specialization.percentage">( {{specialization?.percentage}} %) </span>
            </a>
                            </h4>
                            <p class="mb-0 fs-6">
                                <span class="me-2"><span class="text-dark fw-medium">{{specialization.no_of_courses}} Courses</span>
                                </span>

                                <!-- <span><span class="text-dark fw-medium">{{specialization.total_topics}} </span> Topics
                                </span> -->
                            </p>
                            <!-- <ul>
                                <li *ngFor="let c of specialization.courses">{{c.course_title}} ( {{c.no_of_topics}})</li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <h3>Courses</h3>
    <div class="row row-cols-3" *ngIf="specializations">
        <div class="col" *ngFor="let course of courseList | slice:6">
            <app-course-stats-widget [course]="course"></app-course-stats-widget>

        </div>
    </div> -->
</div>