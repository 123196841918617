<div class="pt-5 pb-5">
    <div class="container" *ngIf="user">
        <!-- User info -->
        <div class="row align-items-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <!-- Bg -->
                <div class="pt-8 rounded-top-md" style="
              background: url(../assets/images/background/profile-bg.jpg) no-repeat;
              background-size: cover;
            "></div>
                <div class="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom-md shadow-sm">
                    <div class="d-flex align-items-center">
                        <div class="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                            <img src="../assets/images/avatar/avatar-1.jpg" class="avatar-xl rounded-circle border border-4 border-white" alt="" />
                        </div>
                        <div class="lh-1">
                            <h2 class="mb-0">
                                {{user.name}}
                                <a href="#" class="text-decoration-none" data-bs-toggle="tooltip" data-placement="top" title="Beginner">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3" y="8" width="2" height="6" rx="1" fill="#754FFE"></rect>
                    <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
                    <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
                  </svg>
                                </a>
                            </h2>
                            <p class="mb-0 d-block">@{{user.username}}</p>
                        </div>
                    </div>
                    <div>
                        <!-- <a href="dashboard-student.html" class="btn btn-outline-primary btn-sm d-none d-md-block">Go to
              Dashboard</a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Content -->
        <div class="row mt-0 mt-md-4">
            <div class="col-lg-3 col-md-4 col-12">
                <!-- Side navbar -->
                <nav class="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav">
                    <!-- Menu -->
                    <a class="d-xl-none d-lg-none d-md-none text-inherit fw-bold" href="#">Menu</a>
                    <!-- Button -->
                    <button class="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary text-light" type="button" data-bs-toggle="collapse" data-bs-target="#sidenav" aria-controls="sidenav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fe fe-menu"></span>
          </button>
                    <!-- Collapse navbar -->
                    <div class="collapse navbar-collapse" id="sidenav">
                        <div class="navbar-nav flex-column">


                            <span class="navbar-header">Account Settings</span>
                            <!-- List -->
                            <ul class="list-unstyled ms-n2 mb-0">
                                <!-- Nav item -->
                                <li class="nav-item active">
                                    <a class="nav-link"><i class="fe fe-settings nav-icon"></i>Edit Profile</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="col-lg-9 col-md-8 col-12">

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0">Profile Details</h3>

                    </div>
                    <div class="card-body">

                        <div>



                            <form class="row" [formGroup]="profileForm" (ngSubmit)="updateProfile()">
                                <div class="mb-3 col-12 col-md-6">
                                    <label class="form-label" for="fname">Name</label>
                                    <input type="text" id="name" formControlName="name" class="form-control" placeholder="Enter Name" required />
                                </div>
                                <div class="mb-3 col-12 col-md-6">
                                    <label class="form-label" for="username">Username</label>
                                    <input type="text" formControlName="username" class="form-control" placeholder="Enter Username" readonly required />
                                </div>
                                <div class="mb-3 col-12 col-md-6">
                                    <label class="form-label" for="fname">Email</label>
                                    <input type="text" id="email" formControlName="email" class="form-control" placeholder="Enter Email" required />
                                </div>
                                <div class="mb-3 col-12 col-md-6">
                                    <label class="form-label" for="fname">Github Username</label>
                                    <input type="text" formControlName="githubUsername" class="form-control" placeholder="Github Username" required />
                                </div>
                                <div class="mb-3 col-12 col-md-6">
                                    <label class="form-label" for="fname">Career Track</label>
                                    <select formControlName="careerTrack" required class="form-control">
                                      <option disabled > -- SELECT --</option>
                                      <option value="{{specialization.name}}" *ngFor="let specialization of specializations">{{specialization.name}}</option>
                                    </select>

                                </div>

                                <div class="col-12">
                                    <!-- Button -->
                                    <button class="btn btn-primary" type="submit" [disabled]="profileForm.invalid">
                    Update Profile
                  </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
