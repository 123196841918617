<div class="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-7 col-lg-7 col-md-12">
                <div>
                    <h1 class="text-white display-4 fw-semi-bold">Getting Started with JavaScript</h1>
                    <p class="text-white mb-6 lead">
                        JavaScript is the popular programming language which powers web pages and web applications. This course will get you started coding in JavaScript.
                    </p>
                    <div class="d-flex align-items-center">
                        <a href="#" class="bookmark text-white text-decoration-none">
                            <i class="fe fe-bookmark text-white-50 me-2"></i>Bookmark
                        </a>

                        <span class="text-white ms-3"><i class="fe fe-user text-white-50"></i> 1200 Enrolled </span>
                        <span class="ms-4">
        <i class="mdi mdi-star me-n1 text-warning"></i>
        <i class="mdi mdi-star me-n1 text-warning"></i>
        <i class="mdi mdi-star me-n1 text-warning"></i>
        <i class="mdi mdi-star me-n1 text-warning"></i>
        <i class="mdi mdi-star me-n1-half text-warning"></i>
        <span class="text-white">(140)</span>
                        </span>
                        <span class="text-white ms-4 d-none d-md-block">
        <svg width="16" height="16" viewBox="0 0 16
                      16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="3" y="8" width="2" height="6" rx="1" fill="#DBD8E9"></rect>
          <rect x="7" y="5" width="2" height="9" rx="1" fill="#DBD8E9"></rect>
          <rect x="11" y="2" width="2" height="12" rx="1" fill="#DBD8E9"></rect>
        </svg>
        <span class="align-middle">
          Intermediate
        </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>