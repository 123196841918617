<div class="container m-3">
    <div class="row mb-4">
        <div class="col">
            <h2 class="mb-0">Courses - {{ specialization?.name}}
                <span class="badge bg-success">{{getSpecializationTopicsCnt()}} Topics </span>
            </h2>
        </div>
    </div>

    <div *ngIf="courses && categories">
        <div class="row" *ngFor="let category of categories">
            <h3 class="text-center secondary">{{ category }}</h3>
            <div class="row row-cols-3">

                <app-course-stats-widget [course]="course" *ngFor="let course of getCourses(category)"></app-course-stats-widget>

            </div>
        </div>
    </div>
</div>