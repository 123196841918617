<div class="container m-3">
    <div class="row mb-4">
        <div class="col">
            <h2 class="mb-0">Users</h2>
        </div>
        <div class="col pull-right">
            <form>
                <input type="text" name="search" [(ngModel)]="searchUser" placeholder="Search user" autocomplete="off">
            </form>
        </div>
    </div>
    <div class="row" *ngIf="users">

        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let user of (users | searchUser: searchUser)">
            <!-- Card -->
            <div class="card mb-4 card-hover">
                <div class="d-flex justify-content-between align-items-center p-4">
                    <div class="d-flex">



                        <div class="ms-3">
                            <h4 class="mb-1">
                                <a routerLink="/users/{{user.username}}" class="text-inherit">
              {{user.name}}
            </a>
                            </h4>
                            <p class="mb-0 fs-6">
                                <!-- <span class="me-2"><span class="text-dark fw-medium">{{user.modules.length}} Modules</span>
                                </span> -->
                                <!-- <span><span class="text-dark fw-medium">34 </span> Hours
                            </span> -->
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
